<section id="services">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/1.jpg"/>
        <img alt class="bg" src="assets/images/bg/2.jpg"/>
        <div class="content colors-e">
            <div class="container">
                <h3><span class="highlight">Nos </span>Services</h3>
                <p class="header-details"><span class="highlight">Que </span> faisons-nous</p>
                <div class="row">
                    <div class="col-md-3 top-line">
                        <h4>Mon chantier<br/> est beau</h4>
                        <p><b>Le chantier</b> est un domaine souvent mal connu et mal perçu du grand public.</p>
                        <p><b>Diffuser une information claire, exhaustive</b> et illustrer nos propos permet de rassurer
                            les riverains, les acquéreurs ou les institutions publiques.</p>
                        <ul class="simple">
                            <li>Réalisation et production <b>de reportages photographiques</b></li>
                            <li>Organisation de <b>séances portraits</b> pour les équipes du chantier</li>
                            <li>Réalisation, montage de <b>Prises de vus aériennes par drone : production de reportages
                                photos complets</b></li>
                        </ul>
                    </div>
                    <div class="col-md-3 top-line">
                        <h4>Oh les travaux <br/>Avancent</h4>
                        <p><b>Le chantier</b> change, évolue en permanence.</p>
                        <p>La vidéo est un moyen efficace de rendre compte de cette dynamique incessante.</p>
                        <ul class="simple">
                            <li><b>Caméras Timelapse filmant le chantier en HD.</b>Création de plateformes sécurisées
                                permettant de visionner les images en direct via le net.
                            </li>
                            <li>Réalisation, montage de <b>Prises de vus aériennes par drone : production de vidéos</b>
                            </li>
                            <li>Montage de films <b>en Motion Design</b> : Animation graphique, interview des équipes et
                                montage dynamique illustrant l’avancement des travaux, et présentant le travail des
                                équipes chantier
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3 top-line">
                        <h4>Parlez<br/> moi</h4>
                        <p>Potion Médiatique propose des outils relayant les informations du chantier, et permettant
                            de le suivre :</p>
                        <ul class="simple">
                            <li>A destination des riverains</li>
                            <ul>
                                <li>Création et gestion de <b>Boîtes aux lettres numériques</b></li>
                                <li>Mise à disposition <b>d’espaces riverains sécurisés permettant de recueillir les
                                    attentes et les craintes</b></li>
                                <li>Développement de <b>plateformes d’échanges riverains</b></li>
                            </ul>
                            <li>A destination des acquéreurs</li>
                            <ul>
                                <li>Création, développement et animation de <b>Sites web chantier</b>, mise à jour
                                    très régulièrement en fonction de l’avancement des travaux
                                </li>
                                <li>Développement <b>d’espaces acquéreurs sécurisés</b></li>
                                <li>Rédaction, mise en page, envoi de <b>Newsletters et relai via les réseaux
                                    sociaux</b></li>
                                <li>Possibilité de relayer ces informations avec des <b>alertes par SMS ou mails</b>
                                </li>
                            </ul>
                        </ul>
                    </div>
                    <div class="col-md-3 top-line">
                        <h4>Gerons<br/> Ensemble</h4>
                        <p>Potion Médiatique vous accompagne dans la gestion de la relation avec les riverains concernés
                            par votre projet immobilier.</p>
                        <ul class="simple">
                            <li>Nous établissons avec vous les éléments de langage permettant de communiquer avec vos
                                interlocuteurs
                            </li>
                            <ul>
                                <li>Organisation de points réguliers avec les équipes</li>
                                <li>Définition du discours à adopter</li>
                                <li>Organisation de réunions riverains</li>
                            </ul>
                            <li>Nous développons en parallèle des outils permettant de relayer votre message</li>
                            <ul>
                                <li>Création, développement et animation de <b>Sites web</b></li>
                                <li>Création et gestion de <b>Boîtes aux lettres numériques</b></li>
                                <li>Mise à disposition <b>d’espaces riverains sécurisés permettant de recueillir les
                                    attentes et les craintes</b></li>
                                <li>Développement de <b>plateformes d’échanges riverains</b></li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>