<section id="home">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/8.jpg" />
        <img alt class="bg" src="assets/images/bg/11.jpg" />
        <div class="content home-alice full-size colors-a background-transparent">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-5 col-md-offset-1">
                        <div class="text-right textillate heading  hidden-xs hidden-sm" data-textillate-options="{loop:true, in:{effect:'flipInY', reverse:true}, out:{effect:'flipOutY', reverse:true}}">
                            <ul class="texts">
                                <li>Nous Sommes Photographes</li>
                                <li>Nous Sommes Videastes</li>
                                <li>Nous Sommes Développeurs</li>
                            </ul>
                        </div>
                        <h1 class="heading text-right">Potion Médiatique</h1>
                        <h4 class="text-right ">
                            Communication et Suivi de chantier
                        </h4>
                        <p class="text-right">
                            <a href="#about" class="button background-60-d heading-d">Qui sommes nous ?</a>
                            <a href="#services" class="button background-60-f heading-f">Notre travail</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>