<section id="mediachantier">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/10.jpg"/>
        <img alt class="bg" src="assets/images/bg/6.jpg"/>
        <div class="content colors-e">
            <div class="container">
                <h3>Nos <span class="highlight">Photographies</span></h3>
                <p class="header-details"><span class="highlight">Admirez</span> votre chantier</p>
                <p class="lead">Riche de plusieurs années d'expérience, nos photographes professionnels vous
                    offrent une excellente qualité d'image et un regard artistique sur vos
                    opérations. À travers les espaces de partages de photos et vidéos que nous vous
                    mettons à disposition ou encore l'appui d'un site internet, votre communication
                    visuelle n'a jamais été autant facilitée.
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <ul class="widget-tabs nav nav-tabs background-lite-e">
                            <li class="active"><a href="index.html#tabdrone" data-toggle="tab">Drône</a></li>
                            <li class=""><a href="index.html#tabphotographie" data-toggle="tab">Photographie</a></li>
                            <li class=""><a href="index.html#tabtimelapse" data-toggle="tab">Timelapse</a></li>
                            <li class=""><a href="index.html#tabvideo" data-toggle="tab">Vidéo</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade active in" id="tabdrone">
                                <p>
                                    Potion Médiatique est habilité au pilotage de drone par la Direction Générale de
                                    l'Aviation Civile (DGAC). Nos équipes réalisent ainsi de nombreuses prises de vues
                                    aériennes afin d'obtenir un regard unique sur votre opération et vous permettre de
                                    communiquer plus efficacement.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabphotographie">
                                <p>
                                    Nos photographes professionnels vous offrent une excellente qualité d'image et un
                                    regard artistique sur vos opérations.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabtimelapse">
                                <p>
                                    Nous posons une ou plusieurs caméras sur votre chantier et nous réalisons des vidéos
                                    accélérées afin de rendre compte de l'avancement du chantier. À la fin de
                                    l'opération vous obtenez un rendu complet en TimeLapse de la construction.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabvideo">
                                <p>
                                    Nos photographes professionnels ainsi que nos Monteurs vidéo vous offrent une excellente qualité de vidéo et un
                                    regard artistique sur vos opérations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="view fluid-height">
                            <img alt="" class="bg img-responsive" src="assets/images/bg/14.jpg">
                            <div class="content incut colors-d background-transparent">
                                <div class="position-middle-center text-center">
                                    <a class="button background-lite-c heading-c" href="/galerie">
                                        Voir la galerie
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="view">
        <img alt class="bg" src="assets/images/bg/1.jpg"/>
        <img alt class="bg" src="assets/images/bg/2.jpg"/>
        <div class="content half-size colors-h">
            <div class="container">
                <div class="show-list slogan">
                    <li>Nous Sommes Photographes</li>
                    <li>Nous Sommes Videastes</li>
                    <li>Nous Sommes Développeurs</li>
                </div>
                <div class="separator"></div>
                <p class="text-center big-font">
                    <em>— Potion Médiatique —</em>
                </p>
            </div>
        </div>
    </div>
</section>