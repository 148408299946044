import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../api/api.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

    loading = true;
    gallery: any;

    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        this.getGallery(3);
    }

    getGallery(id) {
        this.apiService.getGallery(id)
            .subscribe(
                data => {
                    this.gallery = data;
                    this.loading = false;
                },
                error => console.error('There was an error!', error)
            );
    }

}
