<section id="about">
    <div class="view" id="who-we-are">
        <div class="content colors-e">
            <div class="container">
                <h3>Une démarche <span class="highlight">différenciante</span></h3>
                <p class="header-details"><span class="highlight">Qui </span> sommes-nous</p>
            </div>
        </div>
        <div class="content pane">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 stretch-height pane" style="height: 400px;">
                        <div class="view fluid-height">
                            <img alt="" class="bg img-responsive" src="assets/images/bg/9.jpg">
                            <div class="content incut colors-d background-transparent">
                                <div class="position-middle-center text-center">
                                    <!--<a class="button background-lite-c heading-c" href="index.html">Nos actualités</a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 stretch-height pane" style="height: 400px;">
                        <div class="view fluid-height">
                            <div class="content incut colors-e background-solid">
                                <div class="position-middle-center text-justify">
                                    <p class="text-left highlight">Qui sommes nous</p>
                                    <p>
                                        Potion Médiatique développe depuis maintenant de nombreuses années son expertise
                                        dans le domaine de la communication et du suivi de chantier.
                                    </p>
                                    <p>
                                        Reportage photo régulier, prises de vue aérienne habilitées par la direction
                                        générale de l'aviation civile, réalisation de vidéos Timelapse, création et mise
                                        à jour de site internet sont autant d'outils que Potion Médiatique met à
                                        disposition de ses clients pour leur garantir une meilleure visibilité.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>