<section id="riverains">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/1.jpg"/>
        <img alt class="bg" src="assets/images/bg/2.jpg"/>
        <div class="content colors-e">
            <div class="container">
                <h3>Vos <span class="highlight">Riverains</span></h3>
                <p class="header-details"><span class="highlight">Gestion</span> de la relation riverains</p>
                <p class="lead">
                    Potion Médiatique se diversifie et vous propose maintenant de vous accompagner dans la relation que
                    vous avez à entretenir avec les riverains de vos opérations.
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <ul class="widget-tabs nav nav-tabs background-lite-e">
                            <li class="active"><a href="index.html#tabacc" data-toggle="tab">Accompagnement</a></li>
                            <li class=""><a href="index.html#tabsms" data-toggle="tab">Alerte SMS</a></li>
                            <li class=""><a href="index.html#tabpalissade" data-toggle="tab">Palissade de chantier</a>
                            </li>
                            <li class=""><a href="index.html#tabvisite" data-toggle="tab">Visite de chantier</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade active in" id="tabacc">
                                <p>Potion Médiatique se diversifie et vous propose maintenant de vous accompagner
                                    dans la relation que vous avez à entretenir avec les riverains de vos
                                    opérations. Ainsi, nous établissons avec vous les éléments de langage permettant
                                    de communiquer avec vos interlocuteurs :</p>
                                <ul>
                                    <li>Organisation de points réguliers avec vos équipes</li>
                                    <li>Définition du discours à adopter</li>
                                    <li>Organisation de réunions riverains</li>
                                </ul>
                                <p id="phrase100">Nous développons en parallèle de nombreux services permettant de
                                    relayer votre message :</p>
                                <ul>
                                    <li>Développement et animation de <b>sites web</b></li>
                                    <li>Gestion de <b>boîtes aux lettres numériques</b></li>
                                    <li>Création <b>d'espaces riverains sécurisés afin de recueillir les attentes et
                                        les craintes</b></li>
                                </ul>
                                <p><b>Redéfinissez votre relation avec l'extérieur par la confiance grâce à des
                                    services adaptés à chaque besoin</b></p>
                            </div>
                            <div class="tab-pane fade" id="tabsms">
                                <p>
                                    Notre service d’alerte SMS peut être proposé aux riverains comme aux acquéreurs :
                                    vous pouvez nous communiquer une liste de diffusion ou nous pouvons mettre en place
                                    une plateforme d’inscription en ligne. Chaque utilisateur pourra ainsi recevoir des
                                    informations importantes impactant l’accessibilité du quartier : rue bloquée,
                                    problème de stationnement, déviation, etc. Cette solution de communication se veut
                                    réactive et surtout, instantanée.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabpalissade">
                                <p>Derrière la palissade, à l’abri des regards, une aventure est en train de s’écrire
                                    jour après jour. Une palissade constitue, bien souvent, la première et unique vision
                                    que le passant a du projet en chantier. Elle devient un vecteur de communication
                                    pour les riverains et passants.
                                </p>
                                <p>
                                    C’est un espace de parole qui porte l’information hors de l’enceinte du chantier à
                                    destination de ceux qui ne peuvent visiter le site. L’habiller, avec de grands
                                    visuels sur toute sa hauteur et des informations clés sur le déroulé du projet prend
                                    donc tout son sens.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabvisite">
                                <p>
                                    Potion Médiatique est en mesure de vous assister dans l’organisation de visites de
                                    chantiers. Il s’agit là d’une démarche d’information du public, en particulier des
                                    personnes les plus concernées par l’édification de ce bâtiment : riverains,
                                    commerçants, associations de quartier. Ils pourront ainsi être associés à la vie du
                                    projet, à son évolution…
                                </p>
                                <p>
                                    Nous proposons également aux élus locaux chargés de l’éducation de présenter le
                                    chantier dans leurs établissements scolaires. Des visites du chantier, avec un
                                    encadrement et un parcours appropriés, sont envisageables. Les élèves y découvriront
                                    le projet. En suscitant l’intérêt des enfants, nous captons l’attention de leurs
                                    parents, un moyen supplémentaire de remporter leur adhésion.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="slider">
                            <div><img alt="Slide 1" src="assets/images/bg/15.jpg" /></div>
                            <div><img alt="Slide 2" src="assets/images/bg/4.jpg" /></div>
                            <div><img alt="Slide 3" src="assets/images/bg/5.jpg" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>