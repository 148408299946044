import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {GalleryComponent} from './pages/gallery/gallery.component';
import {E404Component} from './error/e404/e404.component';
import {IndexComponent} from './pages/index/index.component';
import {ProjectComponent} from './pages/project/project.component';
import {LegalComponent} from './screens/legal/legal.component';

const routes: Routes = [
    {path: '', component: IndexComponent},
    {path: 'galerie', component: GalleryComponent},
    {path: 'creation-web', component: ProjectComponent},
    {path: 'credit', component: LegalComponent},
    {path: '**', component: E404Component}
];

@NgModule({
    imports: [CommonModule, RouterModule.forRoot(routes)],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule {
}
