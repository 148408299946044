<section id="contact">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/8.jpg"/>
        <img alt class="bg" src="assets/images/bg/10.jpg"/><img
            alt class="bg" src="assets/images/bg/11.jpg"/>
        <div class="content full-size colors-h">
            <div class="container">
                <h2>Contact</h2>
                <p class="header-details">Keep In Touch</p>
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center scroll-in-animation" data-animation="fadeInLeft">
                            <p class="big-font uppercase">
                                Tel: <a href="tel:0650651493" class="highlight">+33 6 50 65 14 93</a>
                            </p>
                            <p class="big-font uppercase">
                                113 Rue d'Alésia
                                <br/>
                                75014 Paris
                            </p>
                            <p class="big-font">
                                <b>
                                    <a href="mailto:elise@potion-mediatique.com">elise@potion-mediatique.com</a><br/>
                                    <a href="https://www.potion-mediatique.com">www.potion-mediatique.com</a>
                                </b>
                            </p>
                            <p class="big-font">
                                <a target="_blank" href="https://www.facebook.com/potionmediatique/">
                                    <span class="fa-stack"><i class="fa fa-circle fa-stack-2x heading"></i>
                                        <i class="fa fa-facebook fa-stack-1x text-background"></i></span></a>
                                <a target="_blank" href="https://www.instagram.com/potionmediatique"><span class="fa-stack"><i
                                        class="fa fa-circle fa-stack-2x heading"></i><i
                                        class="fa fa-instagram fa-stack-1x text-background"></i></span></a>
                                <a target="_blank" href="https://fr.linkedin.com/company/potion-médiatique"><span class="fa-stack"><i
                                        class="fa fa-circle fa-stack-2x heading"></i><i
                                        class="fa fa-linkedin fa-stack-1x text-background"></i></span></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <a class="map-open button background-lite-b border-heading-b heading-b"
                       data-map-overlay=".map-overlay" href="index.html#">Nous localiser</a>
                </div>
            </div>
        </div>
    </div>
</section>