import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    title = 'Potion Mediatique';
    router: string;

    constructor(private isRouter: Router, private route: ActivatedRoute) {
        this.router = isRouter.url;
    }
}
