<footer class="colors-g page-transition non-preloading">
    <!--<div class="container scroll-in-animation" data-animation="fadeInDown">-->
        <!--<div class="row">-->
            <!--<div class="col-md-3">-->
                <!--<h4>Potion<span class="highlight">Médiatique</span></h4>-->
                <!--<div class="footer-description">-->
                    <!--<p>Communication et suivi de chantier</p>-->
                <!--</div>-->
                <!--<p class="social-links">-->
                    <!--<a target="_blank" href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a>-->
                    <!--<a target="_blank" href="https://twitter.com/"><i class="fa fa-instagram"></i></a>-->
                    <!--<a target="_blank" href="http://www.youtube.com/"><i class="fa fa-youtube"></i></a>-->
                    <!--<a target="_blank" href="https://www.linkedin.com/"><i class="fa fa-linkedin"></i></a>-->
                <!--</p>-->
            <!--</div>-->
            <!--<div class="col-md-3">-->
                <!--<h5>Menu</h5>-->
                <!--<ul class="simple">-->
                    <!--<li><a href="#about">Potion Médiatique</a></li>-->
                    <!--<li><a href="#mediachantier">Média chantier</a></li>-->
                    <!--<li><a href="#mediaweb">Média Web</a></li>-->
                    <!--<li><a href="#riverains">Relation riverains</a></li>-->
                    <!--<li><a href="index.html">Nos Actus</a></li>-->
                    <!--<li><a href="https://espace.potion-mediatique.com">Espace Client</a></li>-->
                <!--</ul>-->
            <!--</div>-->
            <!--<div class="col-md-3">-->
                <!--<h5>Dérnieres Actus</h5>-->
                <!--<ul class="simple">-->
                    <!--<li><a href="blog-single-1.html">Post with Featured Image</a></li>-->
                    <!--<li><a href="blog-single-2.html">And Post with Slider</a></li>-->
                    <!--<li><a href="blog-single-3.html">Post with Vimeo Video</a></li>-->
                    <!--<li><a href="blog-single-4.html">Another Post with Image</a></li>-->
                    <!--<li><a href="blog.html">More...</a></li>-->
                <!--</ul>-->
            <!--</div>-->
            <!--<div class="col-md-3">-->
                <!--<h5>Notre Instagram</h5>-->
                <!--<div class="photo">-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/12333983853"-->
                           <!--title="Melbourne Meetup by Envato, on Flickr"><img class="fluid-width"-->
                                                                              <!--src="https://farm8.staticflickr.com/7367/12333983853_8fc462a494_q.jpg"-->
                                                                              <!--alt="Melbourne Meetup"></a>-->
                    <!--</div>-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/13090549273"-->
                           <!--title="Around the Envato Office - Highfive! by Envato, on Flickr"><img class="fluid-width"-->
                                                                                                  <!--src="https://farm8.staticflickr.com/7325/13090549273_ee5c732ce1_q.jpg"-->
                                                                                                  <!--alt="Around the Envato Office - Highfive!"></a>-->
                    <!--</div>-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/12333834195"-->
                           <!--title="Melbourne Meetup by Envato, on Flickr"><img class="fluid-width"-->
                                                                              <!--src="https://farm4.staticflickr.com/3798/12333834195_7dcb472dd5_q.jpg"-->
                                                                              <!--alt="Melbourne Meetup"></a>-->
                    <!--</div>-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/13090422515"-->
                           <!--title="Around the Envato Office - Becca by Envato, on Flickr"><img class="fluid-width"-->
                                                                                              <!--src="https://farm3.staticflickr.com/2279/13090422515_cabc9c447c_q.jpg"-->
                                                                                              <!--alt="Around the Envato Office - Becca"></a>-->
                    <!--</div>-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/13090762874"-->
                           <!--title="Around the Envato Office - Ben &amp; Jane by Envato, on Flickr"><img-->
                                <!--class="fluid-width"-->
                                <!--src="https://farm4.staticflickr.com/3596/13090762874_5f9d99f4e5_q.jpg"-->
                                <!--alt="Around the Envato Office - Ben &amp; Jane"></a>-->
                    <!--</div>-->
                    <!--<div class="col-xs-3 col-sm-2 col-md-4">-->
                        <!--<a target="_blank" href="https://www.flickr.com/photos/we-are-envato/13090456565"-->
                           <!--title="Around the Envato Office - Selina by Envato, on Flickr">-->
                            <!--<img class="fluid-width" src="https://farm4.staticflickr.com/3715/13090456565_df81e68627_q.jpg" alt="Around the Envato Office - Selina"></a>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
    <div class="bottom text-center background-10-b">
        © 2020 Tous droits réservés -
        <a routerLink="/credit" routerLinkActive="active" class="open-overlay-window heading" data-overlay-window=".credits-overlay">
            Mentions Légales
        </a>
    </div>
</footer>