import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../api/api.service';

@Component({
  selector: 'app-chiffres',
  templateUrl: './chiffres.component.html',
  styleUrls: ['./chiffres.component.css']
})
export class ChiffresComponent implements OnInit {

  loading = true;
  numbers: any;
  error: any;

  constructor(private apiService: ApiService) {
  }


  ngOnInit(): void {
    this.fetchEvent();
  }

  fetchEvent() {
    return this.apiService.getNumbersCount().subscribe(
      data => {
        this.numbers = data;
        this.loading = false;
      },
      error => {
        console.error('There was an error!', error);
        this.error = error;
      }
    );
  }

}
