import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {E500Component} from './error/e500/e500.component';
import {E404Component} from './error/e404/e404.component';
import {LandingComponent} from './screens/landing/landing.component';
import {ServicesComponent} from './screens/services/services.component';
import { AboutComponent } from './screens/about/about.component';
import { PhotographieComponent } from './screens/photographie/photographie.component';
import { WebComponent } from './screens/web/web.component';
import { FooterComponent } from './screens/footer/footer.component';
import { ChiffresComponent } from './screens/chiffres/chiffres.component';
import { RiverainsComponent } from './screens/riverains/riverains.component';
import { ContactComponent } from './screens/contact/contact.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ProjectComponent } from './pages/project/project.component';
import { IndexComponent } from './pages/index/index.component';
import { LegalComponent } from './screens/legal/legal.component';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        E500Component,
        E404Component,
        LandingComponent,
        ServicesComponent,
        AboutComponent,
        PhotographieComponent,
        WebComponent,
        FooterComponent,
        ChiffresComponent,
        RiverainsComponent,
        ContactComponent,
        GalleryComponent,
        ProjectComponent,
        IndexComponent,
        LegalComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
