<div class="page-border bottom colors-e background-solid">
    <a href="#top" class="hover-effect">
        <span class="highlight">Haut</span> de page</a>
</div>
<div class="page-border left colors-e background-solid">
    <ul>
        <li><a href="https://www.facebook.com/potionmediatique/" target="_blank"><i class="fa fa-facebook"></i></a></li>
        <li><a href="https://www.instagram.com/potionmediatique" target="_blank"><i class="fa fa-instagram"></i></a></li>
        <li><a href="https://fr.linkedin.com/company/potion-médiatique" target="_blank"><i class="fa fa-linkedin"></i></a></li>
    </ul>
</div>
<!-- BEGIN: Top menu -->
<div class="page-border right colors-e background-solid"></div>
<nav class=" navbar-default navbar-fixed-top colors-e background-solid" role="navigation"
     id="top-nav">
    <div class="container">
        <div class="navbar-header">
            <a class="menu-toggle navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
               href="#"><span></span></a>
            <a class="navbar-brand" href="">
                <img src="assets/images/logo.png" width="70" class="img-responsive">
            </a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li><a href="#about" class="hover-effect">Potion Médiatique</a></li>
                <li><a href="#mediachantier" class="hover-effect">Média chantier</a></li>
                <li><a href="#mediaweb" class="hover-effect">Média Web</a></li>
                <li><a href="#riverains" class="hover-effect">Relation riverains</a></li>
                <li><a href="https://espace.potion-mediatique.com" class="hover-effect" target="_blank">
                    Espace Client</a></li>
            </ul>
        </div>
    </div>
</nav>
<!-- END: Top menu -->
<ul id="dot-scroll" class="colors-e background-solid"></ul>
<div class="overlay-window map-overlay colors-f background-95-f">
    <div class="overlay-control background-90-f">
        <a class="cross" href="index.html#"></a>
    </div>
    <div class="overlay-view">
        <div class="map-canvas" data-latitude="48.828634" data-longitude="2.323884" data-zoom="14">
            <div class="map-marker" data-latitude="48.828634" data-longitude="2.323884"
                 data-text="Notre localisation"></div>
        </div>
    </div>
</div>