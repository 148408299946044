import {Component, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    // TODO api key from webservices
    API_KEY = '#';
    private apiUrl = 'https://espace.potion-mediatique.com/api';

    constructor(private httpClient: HttpClient) {
    }

    public getNumbersCount() {
        return this.httpClient.get(`${this.apiUrl}/espace/count`);
    }

    public getGallery(id) {
        return this.httpClient.get(`${this.apiUrl}/gallery/${id}`);
    }

    public getActualities() {
        return this.httpClient.get(`${this.apiUrl}/actualities/list/${this.API_KEY}`);
    }

    public getActuality(id) {
        return this.httpClient.get(`${this.apiUrl}/actuality/${id}`);
    }

    public getLastActuality() {
        return this.httpClient.get(`${this.apiUrl}/actuality/last/${this.API_KEY}`);
    }


}
