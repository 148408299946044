<section id="gallery" style="margin-bottom: 30px">
    <div class="view">
        <img alt class="bg" src="assets/images/bg/1.jpg"/>
        <img alt class="bg" src="assets/images/bg/2.jpg"/>
        <div class="content no-bottom-padding colors-e">
            <div *ngIf="loading ; else loaded">
                <div class="fa-3x text-center">
                    <i class="fa fa-spinner fa-pulse"></i>
                </div>
            </div>
            <ng-template #loaded>
                <ng-container *ngFor="let gal of gallery">
                    <div class="container">
                        <h2>Notre <span class="highlight">Galerie</span></h2>
                        <p class="header-details"><span class="highlight">Quelques</span> photos</p>
                        <p class="lead">
                            {{ gal.content }}
                        </p>
                    </div>

                    <div class="gallery background-e" data-default-group="all" data-overlay=".gallery-overlay">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="grid">
                                    <ng-container *ngFor="let slide of gal.photos">
                                        <div class="item col-md-3 col-sm-4 col-xs-6">
                                            <a href="{{ slide.url }}" class="hover-overlay" target="_blank">
                                                <img [src]="slide.url" [alt]="gal.title" [title]="gal.title"
                                                     style="height: 200px !important;"/>
                                                <div class="overlay background-90-e">
                                                    <div class="hidden-xs">
                                                        <p class="title heading-e">Voir l'image</p>
                                                        <p class="text-center"><i class="fa fa-picture-o heading-e"></i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
    </div>
</section>