<section id="chiffres">
    <div class="view" id="numbers">
        <img alt class="bg" src="assets/images/bg/12.jpg"/>
        <img alt class="bg" src="assets/images/bg/7.jpg"/>
        <div class="content half-size colors-h">
            <div class="container-fluid">
                <h3>Quelques chiffres <span class="highlight">en direct</span></h3>
                <p class="title"><span class="highlight">Potion Médiatique</span> c'est</p>
                <div *ngIf="loading ; else loaded">
                    <div class="fa-3x text-center">
                        <i class="fa fa-spinner fa-pulse"></i>
                    </div>
                </div>
                <ng-template #loaded>
                    <div class="row counters">
                        <div class="col-md-3 counter background-25-b">
                            <div class="count player row heading background-10-light">{{ numbers.chantiers }}</div>
                            <div class="caption">Chantiers</div>
                        </div>
                        <div class="col-md-3 counter background-35-h">
                            <div class="count player row heading background-10-light">{{ numbers.reportages }}</div>
                            <div class="caption">Reportages</div>
                        </div>
                        <div class="col-md-3 counter background-25-b">
                            <div class="count player row heading background-10-light">{{ numbers.photos }}</div>
                            <div class="caption">Photos</div>
                        </div>
                        <div class="col-md-3 counter background-35-h">
                            <div class="count player row heading background-10-light">{{ numbers.timelapses }}</div>
                            <div class="caption">Timelapses</div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="container">
                <p class="text-center ">
                    Nous sommes des experts en média, rejoignez nous pour obtenir le meilleur de vos chantiers.
                </p>
            </div>
        </div>
    </div>
</section>
