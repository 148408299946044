<section id="mediaweb">
    <div class="view">
        <div class="content colors-e">
            <div class="container">
                <h3>Sites <span class="highlight">Web</span></h3>
                <p class="header-details"><span class="highlight">Communiquer </span>sur l'avancement du chantier</p>
                <p class="lead">
                    Notre équipe de développeurs est à même de créer des sites internet pour répondre au mieux à vos
                    attentes. Un site internet permettra à vos clients de suivre l'avancement de votre chantier grâce à
                    la publication régulière d'articles, photos et vidéos par nos soins.
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <div class="view fluid-height">
                            <img alt="" class="bg img-responsive" src="assets/images/bg/13.jpg" >
                            <div class="content incut colors-d background-transparent">
                                <div class="position-middle-center text-center">
                                    <a class="button background-lite-c heading-c" href="/creation-web">
                                        Voir nos projets
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <ul class="widget-tabs nav nav-tabs background-lite-e">
                            <li class="active"><a href="index.html#tabweb" data-toggle="tab">Site individuel</a></li>
                            <li class=""><a href="index.html#tabwebphoto" data-toggle="tab">Espace Média</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade active in" id="tabweb">
                                <p>
                                    Nous sommes à l'écoute de tous vos envies et nous vous conseillerons sur
                                    l'organisation
                                    qui vous conviennent le mieux afin de concevoir votre site web d'atualités de
                                    chantier efficaces, sécuritaires et fiables.
                                    Vous disposerez d'un site entièrement dédié à votre ou vos chantier(s) avec toutes
                                    les
                                    actualités et les reportage photos informant de l'avancé.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="tabwebphoto">
                                <p>
                                    Ayez un accès unique à vos chantiers, vos reportages photos, vos vidéos timelapses
                                    et vos
                                    documents directement depuis notre espace client.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>